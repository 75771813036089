const cats = [
`
／＞　 フ
| 　_　_|
／ ミ＿xノ
/　　　　 |
/　 ヽ　　 ﾉ
│　　|　|　|
／￣|　　 |　|　|
(￣ヽ＿_ヽ_)__)
＼二)`,
`
∩――――∩
||     ∧ ﾍ　 ||
||    (* 'ｰ') ZZzz
|ﾉ^⌒⌒づ￣  ＼
(　ノ　　⌒ ヽ ＼
＼　　||￣￣￣￣￣||
　 ＼,ﾉ||
`,
`
/ᐠ_ ꞈ _ᐟ＼
`,
`
/＼__/＼
꒰ ˶• ༝ •˶꒱ ~♡︎
/ v v ＼
|        |
づ__づ
`,
`
ハ____ハ    ｡ﾟﾟ･｡･ﾟﾟ｡
꒰   ⬩ ω ⬩  ꒱  ˚｡          ｡˚
 |   つ ~ okay　ﾟ ･｡･ﾟ
`,
`
ฅ(ﾐ⚈ ﻌ ⚈ﾐ)ฅ
`,
`
•.,¸,.•*'•.,¸¸,.•*¯ ╭━━━━╮
•.,¸,.•*¯'•.,¸,.•*¯.|:::::::::: /___/
•.,¸,.•*¯'•.,¸,.•* <|:::::::::(｡ ●ω●｡)
•.,¸,.•¯•.,¸,.•╰ * >し------し---Ｊ
`
];


export default cats